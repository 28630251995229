.App {
  text-align: center;
  background-color: #282c34;
}
.App-header {
  background-color: #282c34;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1em;
  color: white;
  flex-grow: 1;
  padding: 0 2rem;
  outline: 0 !important;
}

.App-link {
  color: #61dafb;
}

.label {
  margin-top: 2em;
}

footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  color: white;
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

footer p {
  margin-block: 0.3em;
}